/** Slider */
.slider {
  height: 100% !important;
}

.slider-content.slider-small .inner .slider_body {
  max-height: 60px;
}

.slider-content .inner {
  padding: 0 60px !important;
}

.slider-content .inner h1.slider_title {
  color: #ffffff;
  font-weight: bold;
  text-shadow: 2px 2px 4px #000000;
  text-transform: none;
  /**
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    */
  margin: 0 -35px;
}

.slider-content .inner .slider_body {
  max-height: 160px;
  overflow: auto;
  text-align: left;
}

.slider-content .inner ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.slider-content .inner ::-webkit-scrollbar {
  width: 6px;
  margin-left: 5px;
  background-color: #3db5e6;
}

.slider-content .inner ::-webkit-scrollbar-thumb {
  background-color: #3db5e6;
}

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: .9s;
  transition-delay: .9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}

.slide.animateOut p {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  border: none;
  background: #ffc800;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  overflow: hidden;
}

button:hover {
  color: #FFFFFF;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  background-size: cover !important;
}

.slide::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #FFFFFF;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {

  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {

  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

.previousButton polygon,
.nextButton polygon {
  fill: #ffffff;
}

.Toastify__toast-container--top-right {
  top: 5em !important;
  right: 1.3em !important;
}

.Toastify__toast {
  border-radius: 4px !important;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px !important;
}

.Toastify__toast .Toastify__progress-bar--dark {
  background: #ffc800;
}

.Toastify__toast.Toastify__toast--error {
  color: #bf2600;
  background-color: #ffebe6;
  border-left: 8px solid;
}

.Toastify__toast.Toastify__toast--error .Toastify__toast-body {
  margin: 3px 5px 0 0 !important;
  flex: 1 1 auto;
}

.Toastify__toast.Toastify__toast--error .Toastify__close-button {
  color: #bf2600;
  background: transparent;
  margin-top: 2px;
}

.Toastify__toast.Toastify__toast--success {
  color: #36b37e;
  background-color: #e3fcef;
  border-left: 8px solid;
}

.Toastify__toast.Toastify__toast--success .Toastify__toast-body {
  margin: 3px 5px 0 0 !important;
  flex: 1 1 auto;
}

.Toastify__toast.Toastify__toast--success .Toastify__close-button {
  color: #36b37e;
  background: transparent;
  margin-top: 2px;
}

.uploadInput {
  height: 30px;
}

@media print {

  .alh_noprint,
  .alh_widget_cont {
    display: none !important;
  }

  .alh_mobileHide {
    display: table-cell !important
  }

  .alhTable .tableActions {
    width: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
    border: 0px !important;
    border-spacing: 0px !important;
  }

  .container {
    overflow: hidden;
  }

  .container .alh_frontBoxes {
    padding: 0;
  }

  .col-print-6 {
    width: 50% !important;
    float: left;
  }

  .submitButton {
    display: none;
  }

  .nav-tabs.nav {
    display: none;
  }
}

@font-face {
  font-family: 'Exo 2';
  src: url("../assets/font/exo-2-v4-latin-regular.woff2") format("woff2");
}

html {
  height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 14px !important;
  color: #333;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

body.login {
  background-image: url(../assets/images/login-register-bg.jpg);
}

body a,
body .alh_onclicklink {
  color: #3db5e6;
  cursor: pointer;
}

body a:hover,
body .alh_onclicklink:hover {
  color: #3db5e6;
}

body table a {
  filter: brightness(0.85);
}

body table a:hover {
  filter: brightness(1);
}

.alh_clickicon .svg-inline--fa {
  cursor: pointer;
}

b {
  font-weight: bold !important;
}

.alh_mobileShow {
  display: none;
}

.alh_bold {
  font-weight: bold;
}

.alh_print_right {
  width: 100%;
  text-align: right;
}

.alh_alignArrowButtons {
  display: flex;
  align-items: center;
}

.alh_small {
  font-size: 0.7rem;
}

.inputField select {
  font-size: 14px;
}

.inputField input[type="date"] {
  text-transform: uppercase;
}

input:disabled {
  pointer-events: none;
}

/** search field */
.isform-control {
  flex: 1 1 auto;
}

.alh_searchItem {
  display: block;
  textAlign: left;
  cursor: pointer;
}

.isform-control-disabled.isSearchAutomcomplete .wrapper {
  background-color: lightgrey;
}

.isform-control-disabled.isSearchAutomcomplete .wrapper input {
  pointer-events: none;
}

.isSearchAutomcomplete .wrapper {
  padding-left: 8px;
  padding-right: 8px;
}

.isSearchAutomcomplete .wrapper:focus-within {
  z-index: 502;
}

.isSearchAutomcomplete .wrapper input {
  padding-left: 0;
}

.custom-select {
  height: calc(1.5em + .75rem + -2px) !important;
  padding: 0.3rem 1.7rem .3rem .5rem !important;
}

.alh_checksubtextalign {
  position: relative;
  top: -2px;
}

.pointer {
  cursor: pointer;
}

Button {
  border-radius: 0 !important;
}

Button .alh_loadingSpinner {
  padding-left: 10px;
}

Button.alhButtonSpaceBottom {
  margin-bottom: 5px;
}

Button.alhButton.alhButtonFullWidth {
  width: 100%;
}

Button.alhButton:disabled {
  cursor: no-drop;
}

Button.alhButton.alhButton_mgsave {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.underConstruction {
  align-content: center;
  text-align: center;
}

.notAllowed {
  align-content: center;
  text-align: center;
}

.form-group.small-form-group {
  margin-bottom: 0px;
}

.formInline {
  width: 70px !important;
  display: inline !important;
}

.formInline.formInlineSpacing {
  margin: 0 5px;
}

.btn-primary {
  color: #fff;
  background-color: #3db5e6 !important;
  border-color: #97d7f2 !important;
}

.btn-primary:hover {
  background-color: #ffc800 !important;
  border-color: white !important;
}

.btn-primary svg.svg-inline--fa.fa-info-circle.fa-w-16.infoLeftSpace.lightIcon {
  color: #ffffff;
}

h1 {
  font-size: 1.5em !important;
  text-transform: uppercase;
}

h2 {
  font-size: 1.4em !important;
}

h3 {
  font-size: 1.3em !important;
}

.alh_faicon {
  color: inherit;
  line-height: 1rem;
}

/** NAVBAR */
.alhScrolled .navbar.navbar-light img {
  height: 50px;
}

.alhScrolled .navbar.navbar-light .navbar-nav .nav-item>a {
  padding: 11px 5px;
}

.navbar.navbar-light {
  background-color: #3db5e6;
  padding: 0 15px !important;
  z-index: 503;
}

.navbar.navbar-light .alh_logobar {
  max-height: 90px;
}

.navbar.navbar-light button:hover {
  background: #ffc800;
}

.navbar.navbar-light .alh-nav-head {
  text-transform: uppercase;
}

.navbar.navbar-light .alh-nav-head .dropdown-menu {
  text-transform: none;
}

.navbar.navbar-light .navbar-nav .nav-item {
  padding: 5px 5px;
}

.navbar.navbar-light .navbar-nav .nav-item:hover {
  background: #ffcc00;
}

.navbar.navbar-light .navbar-nav .show>.nav-link,
.navbar.navbar-light .navbar-nav .nav-link {
  color: #ffffff !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.navbar.navbar-light .navbar-nav .show>.nav-link:hover,
.navbar.navbar-light .navbar-nav .nav-link:hover {
  color: #ffffff;
}

.navbar.navbar-light .navbar-nav .dropdown-menu {
  border-radius: 0;
  min-width: 12rem;
  background: #4f5458;
}

.navbar.navbar-light .navbar-nav .dropdown-menu .nav-link {
  margin: 0;
  padding: 5px 10px;
  font-size: 0.8rem;
}

.navbar.navbar-light .navbar-nav .dropdown-menu .nav-link:hover {
  background: #212222;
}

.nav-tabs.nav {
  margin-bottom: 20px;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 5px solid #ffc800 !important;
}

.nav-tabs a {
  color: #000000;
}

.nav-tabs a:hover {
  border-color: transparent !important;
  color: #000000;
  border-bottom: 4px solid #a5a5a5 !important;
}

/** CORE */
.alhCore {
  margin: 25px auto;
  margin-top: 120px;
  min-height: calc(100vh - 178px);
}

.alhCore .loginForm {
  position: absolute;
  text-align: center;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 0;
}

.alhCore .loginForm .alh_logo {
  text-align: center;
}

.alhCore .loginForm .alh_logo img {
  width: 115px;
  padding-bottom: 25px;
  vertical-align: center;
}

.alhCore .loginForm .loginHeader {
  margin-bottom: 25px;
  font-weight: 700;
}

.alhCore .loginForm .input-group-text {
  width: 40px;
}

.alhCore .alh_pageheader {
  margin: 5px;
  margin-left: 0px;
}

.alh_alignPageheader {
  padding-top: 4px;
}

.alhCore .alh_pageheader_topspace {
  margin-top: 20px;
}

.alhCore .alh_subheader {
  margin: 0 0 15px 0;
  font-size: 0.8rem;
}

/** JUMBO */
.alh_jumbo {
  position: relative;
  z-index: 2;
  background-size: cover;
  padding: 20px 0 10px 0 !important;
  background-color: #e9ecef !important;
  box-shadow: 3px 8px 17px #8888887d;
}

.alh_jumbo div {
  line-height: 1.3em;
}

/** FOOTER */
.alhFooter {
  background-color: #4e5457;
  color: white;
  text-align: left;
  font-size: 14px;
  line-height: 23px;
  padding: 5px 15px;
  z-index: 501;
}

.alhFooter a {
  color: #ffffff;
}

.alhFooter a:hover {
  color: #ffc800;
}

.alhFooter .alh_mgsearch>div {
  width: fit-content;
  margin: auto;
}

.alhFooter .alh_mgsearch input {
  width: 100px;
  margin-left: 10px;
  padding: 2px 3px;
  height: 24px;
}

.alhFooter .alh_flinks {
  text-align: right;
}

.alhFooter .alh_icon {
  margin-left: 5px;
  cursor: pointer;
}

.warning-text {
  color: #f58080;
}

.inCheckbox {
  padding-left: 5px;
}

.infoSmall {
  font-size: 11px;
}

.infoSmall.warning {
  color: #f58080;
}

.infoSmall.success {
  color: #36b37e;
}

.highlightValue {
  color: #36b37e;
  font-weight: bold;
}

.alh_mitglied_noterwb {
  color: #fd4747;
  font-weight: bold;
  margin-top: 10px;
  font-size: 0.8rem;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

.alh_widget_bst {
  font-weight: bold;
  margin-bottom: 10px;
}

/** Normal Table */
.alh_tablemaxheight {
  overflow-y: auto;
  max-height: 235px;
}

.alh_tablemaxheight thead th {
  position: sticky;
  top: -1px;
  background-color: #ffffff;
}

.alh_tablemaxheight::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.alh_tablemaxheight::-webkit-scrollbar {
  width: 6px;
  margin-left: 5px;
  background-color: #3db5e6;
}

.alh_tablemaxheight::-webkit-scrollbar-thumb {
  background-color: #3db5e6;
}

.alhTable {
  background-color: #ffffff;
}

.alhTable .alhTable_SmallInfo {
  font-size: 0.8rem;
  color: #fd4747;
}

.alhTable th {
  font-weight: normal;
  vertical-align: middle !important;
}

.alhTable th.alh_mgEditLables {
  width: 190px;
}

.alhTable th.alhtable_nr {
  width: 80px;
}

.alh_ChecklistContainerBorder {
  width: 100%;
}

.alh_checklistRight>div {
  display: table-cell;
  padding-right: 10px;
}

.alh_opacity {
  opacity: 0.5;
  text-decoration: line-through;
}

.alh_ChecklistContainerBorder:first-of-type {
  border-top: 1px solid;
  border-color: #3db5e6;
}

.alh_ChecklistContainerBorder {
  border-bottom: 1px solid;
  border-color: #3db5e6;
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alhTable thead th {
  font-weight: bold;
  padding-right: 0.3rem;
}

.alhTable tr.highlight {
  background-color: #c5e9f7 !important;
}

.alhTable tr.highlight:hover {
  background-color: inherit !important;
}

.alhTable tr.highlight th {
  font-weight: bold;
}

.alhTable tr.warning {
  background-color: #ffdede !important;
}

.alhTable tr.deleted {
  text-decoration: line-through;
  background-color: #ffdede !important;
}

.alhTable tr .alh_tablecheckbox {
  width: 30px;
  text-align: center;
}

.alhTable tr td.alh_centerWithInput {
  padding-top: 9px;
}

.alhTable .alh_notizaufgaben .alh_notizaufgabenDatum {
  width: 85px;
}

.alhTable .alh_notizaufgaben .alh_notizaufgabenTitle {
  width: 40%;
}

.alhTable .alh_notizaufgaben .alh_notizaufgabenAction {
  width: 75px;
}

.alhTable .alh_table_prio {
  width: 52px;
}

.alhTable .tableActions {
  width: 52px;
  text-align: center;
}

.alhTable .tableActions a {
  color: #000000;
}

.alhTable.alhTable_clearMarginBottom {
  margin-bottom: inherit;
}

.alhTable.alhTableThFixer tr th:nth-child(1) {
  width: 170px;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: white;
}

.alh_clearMarginBottom {
  margin-bottom: 0 !important;
}

.halfSize {
  width: 50%;
}

.halfSize_float {
  width: 50%;
  float: left;
}

.onethirdSize_float {
  width: 33%;
  float: left;
}

.twothirdSize_float {
  width: 67%;
  float: left;
}

.fullSize {
  width: 100%;
}

/** Boxes */
.alh_frontBoxes {
  background-color: #e9ecef;
  padding: 10px 10px;
  min-height: 284px;
  box-shadow: 3px 8px 17px #8888887d;
  height: 100%;
}

.alh_frontBoxes.clearMinheight {
  min-height: inherit;
}

.alh_frontBoxes.maxHeight {
  max-height: 450px;
  overflow-y: auto;
}

.alh_frontBoxes .info {
  margin: 10px;
  text-align: center;
  font-weight: bold;
}

.alh_frontBoxes h2 {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 15px;
}

.alh_frontBoxes h3 {
  font-size: 14px;
}

.alh_frontBoxes h4 {
  font-size: 14px;
}

.alh_frontBoxes .alhProgressbar {
  background-color: #ffffff;
}

/** Accordion */
.alhAccordion {
  margin: 15px 0 0 0;
}

.alhAccordion .alhAcc_Item {
  border-radius: 15px;
  background-color: #ffffff;
  margin: 0 0 10px 0;
}

.alhAccordion .alhAcc_Item .alhAcc_Header {
  font-weight: bold;
  margin: 0 0 10px 0;
  padding: 10px;
  background-color: #c5e9f7;
  cursor: pointer;
}

.alhAccordion .alhAcc_Item .alhAcc_Header_MyBst {
  margin-bottom: 0px;
}

.alhAccordion .alhAcc_Item .alhAcc_Header:hover {
  background-color: #ffc800;
}

.alhAccordion .alhAcc_Item .alhAcc_Header .alhAll_HeaderTitle {
  float: left;
  width: 75%;
}

.alhAccordion .alhAcc_Item .alhAcc_Header .alhAll_HeaderCheck {
  float: left;
  width: 10%;
}

.alhAccordion .alhAcc_Item .alhAcc_Header .alhAll_HeaderCheck .inCheckbox label,
.alh_ChecklistContainerBorder .alh_checklistRight .inputField .inCheckbox label {
  margin-bottom: 0;
}

.alhAccordion .alhAcc_Item .alhAcc_Header .alhAll_HeaderInfo {
  float: right;
}

.alhAccordion .alhAcc_Item .alhAcc_Header .alhAll_HeaderInfo span {
  padding-left: 5px;
}

.alhAccordion .alhAcc_Item .alhAcc_Body {
  padding: 0px 0;
}

.alhAccordion .alhAcc_Item .alhAcc_Body .alhAcc_Notiz,
.alhAccordion .alhAcc_Item .alhAcc_Body .alhAcc_next {
  padding: 10px 10px;
  text-align: end;
}

.alhAccordion .alhAcc_Item .alhAcc_Body .alhAcc_Frage {
  padding: 5px 10px;
}

.alhAccordion .alhAcc_Item .alhAcc_Body .alhAcc_Frage .alhAcc_FrageName {
  float: left;
  width: calc(100% - 130px);
}

.alhAccordion .alhAcc_Item .alhAcc_Body .alhAcc_Frage .alhAcc_FrageCheck {
  float: left;
  width: 100px;
}

.alhAccordion .alhAcc_Item .alhAcc_Body .alhAcc_Frage .alhAcc_FrageCheck .alhAcc_noshow {
  visibility: hidden;
}

.alhAccordion .alhAcc_Item .alhAcc_Body .alhAcc_Frage .alhAcc_FrageCheck label {
  padding-right: 5px;
  margin-bottom: 0;
}

.alhAccordion .alhAcc_Item .alhAcc_Body .alhAcc_Frage .alhAcc_FrageOptCheck {
  padding-right: 5px;
}

.alhAccordion .alhAcc_Item .alhAcc_Body .alhAcc_Frage:hover {
  background-color: #ffe999;
}

.alhAccordion .alhAcc_Item.alhAcc_Close .alhAcc_Body {
  display: none;
}

.alhAccordion .alhAcc_Item .alhAcc_checkboxinline {
  display: contents;
}

/* MyBst Accordion */

.myBst_Line {
  display: flow-root;
}

.myBst_Line>.inputField {
  display: contents;
}

.myBst_Line>.myBst_EditControls {
  display: flex;
}

.myBst_Line>.myBst_ChildrenEditControls {
  display: flex;
  flex-direction: column;
}

/** BP */
.alh_iconright {
  float: right;
}

.alhBP_tripple {
  display: flex;
  margin: 10px 0;
}

.alhBP_tripple>div {
  flex: 1;
  margin-top: 5px;
}

.alhBP_tripple>div.inputField {
  margin-top: 0;
}

.alhBP_tripple .form-group {
  margin: 0;
}

.alhBP_LegCheck {
  color: #28a745;
  font-size: 1rem;
}

.alhBP_LegGrey {
  color: #4e5457;
  font-size: 1rem;
}

.alh_bpaendern {
  margin-bottom: 5px;
  padding: 8px 5px;
  background-color: #e9ecef;
  display: flex;
}

.alh_bpaendern>div:nth-child(1) {
  width: 100px;
}

.alhBP_Leg {
  margin: 5px 0;
}

.alhBP_Leg .alhBP_LegItem {
  padding: 2px 0;
}

.alhBP_Leg .alhBP_LegItem.alhBP_LegGreen {
  color: #28a745;
}

.alhBP_Leg .alhBP_LegItem .alhBP_LegCheck,
.alhBP_Leg .alhBP_LegItem .alhBP_LegXMark {
  padding-right: 5px;
  font-size: 1.1rem;
}

.alhBP_Leg .alhBP_LegItem .alhBP_LegCheck {
  color: #28a745;
}

.infoLeftSpace {
  margin-left: 5px;
}

.infoRightSpace {
  margin-right: 5px;
}

.dps_onlyMarginLeft {
  margin: 0px 0px 0px 5px !important;
}

.alh_infoinputext {
  font-size: 1rem;
  margin: auto 5px;
}

/** Buttons */
.faButton {
  cursor: pointer;
  align-items: baseline;
  margin-right: 5px;
}

.faButtonSpaceright {
  margin: 0 5px;
}

.alh_rowTopList {
  margin: 0 -30px !important;
  margin-bottom: 10px !important;
}

.alh_rowTopList.alh_clearMarginBottom {
  margin-bottom: 0 !important;
}

.alh_containerNoPadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.alh_container_home {
  margin-bottom: 2rem;
}

.alh_spacerTop {
  margin-top: 45px;
}

.alh_rowSpacer {
  margin-bottom: 10px;
}

.alh_rowSpacer .alh_colData {
  background-color: #e9ecef;
  margin: 0 0 0 0;
  padding: 5px 10px;
}

.alh_rowSpacer .alh_colData h3 {
  font-size: 19px;
  color: #000000;
  padding: 8px 0 3px 0;
}

.alh_rowSpacer .alh_colData table {
  margin: 5px 0 5px 0 !important;
}

.alh_rowSpacer .alh_colData table tr td:first-child {
  font-weight: bold;
  width: 150px;
}

.alh_rowSpacer .alh_colData table tr td {
  vertical-align: middle;
}

.alh_rowSpacer .alh_colData table .large {
  width: 300px !important;
}

.alh_rowSpacer .alh_colData table .input-group,
.alh_rowSpacer .alh_colData table .form-group {
  margin-bottom: 0 !important;
}

/** Dropzones */
.dzu-dropzone {
  background-color: #ffffff;
  overflow: auto !important;
  border: 1px solid lightgrey !important;
  min-height: 110px !important;
}

.dzu-dropzone .dzu-inputLabel {
  color: #858585;
  font-weight: normal;
  font-family: inherit;
  font-size: 0.7rem;
  padding: 20px;
  text-align: center;
}

.dzu-dropzone .dzu-previewContainer {
  padding: 0px 20px;
}

.dzu-dropzone label.dzu-inputLabelWithFiles {
  color: #000000;
}

.dzu-dropzone .dzu-inputLabelWithFiles {
  display: none;
}

.alh_counter {
  position: absolute;
  top: 32px;
  font-size: 9px;
  font-weight: bold;
  padding: 2px 6px;
  color: #fd4747;
  font-family: tahoma, arial, 'sans-serif';
  background-color: #ffffff;
  border-radius: 13px;
  margin-left: 10px;
}

.alh_stat .alh_stat_keys {
  margin-bottom: 10px;
}

.alh_stat .alh_stat_keys span {
  font-weight: bold;
}

.alh_stat .alh_stat_keys span.success {
  color: #3db5e6;
}

.alhScrolled .alh_widget_cont {
  top: 60px;
}

.alh_widget_cont {
  position: fixed;
  right: 12px;
  top: 100px;
  margin: 10px;
  z-index: 500;
}

.alh_widget_cont .alh_widget_item {
  cursor: pointer;
}

.alh_widget_cont .alh_widget_item img {
  width: 35px;
  background-color: #e9ecef;
  box-shadow: 3px 5px 6px #8888887d;
  padding: 6px;
  border-radius: 40%;
  margin-bottom: 10px;
  height: 35px;
}

.alh_img100 {
  width: 100%;
}

.lightIcon {
  color: #807f7f;
}

.popover {
  max-width: 600px !important;
}

.popover .alh_popover {
  width: 600px;
  padding: 5px;
  overflow: auto;
}

.popover .alh_popover h1 {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.popover .alh_popover h2 {
  font-size: 0.8rem;
  margin-bottom: 2px;
}

.alhLoadingPos {
  margin: 25px 0;
}

.alhLoadingRel div {
  position: relative !important;
}

/** sort */
th.alh_sort_active {
  color: #ffc800;
  cursor: pointer;
  margin-right: 10px;
}

th.alh_sort_active.alh_sort_active_up::after {
  content: "";
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ffc800;
  margin: 7px 0px 0px 5px;
  position: relative;
  top: -12px;
}

th.alh_sort_active.alh_sort_active_down::after {
  content: "";
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ffc800;
  margin: 7px 0px 0px 5px;
  position: relative;
  top: 12px;
}

th.alh_sort {
  cursor: pointer;
  padding-right: 20px;
}

.alh_mgp_infoprint {
  line-height: 1rem;
  font-size: 0.7rem;
  padding-bottom: 10px;
}

.alh_iconcont {
  margin-top: 3px;
  font-size: 1.1em;
  text-align: right;
}

.alh_hilfelink {
  position: absolute;
  top: 15px;
  right: 4px;
}

.pulsate {
  /* Chrome, Safari, Opera */
  -webkit-animation: pulsate 1s infinite;
  /* Internet Explorer */
  -ms-animation: pulsate 1s infinite;
  /* Standard Syntax */
  animation: pulsate 1.25s infinite;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes pulsate {
  100% {
    color: rgba(0, 0, 0, 0.84);
  }

  0% {
    color: rgba(255, 0, 255, 0);
  }
}

/* Internet Explorer */
@-ms-keyframes pulsate {
  110% {
    color: black;
  }

  0% {
    color: rgba(255, 255, 0, 0);
  }
}

/* Standard Syntax */
@keyframes pulsate {
  110% {
    color: black;
  }

  0% {
    color: rgba(0, 255, 255, 0);
  }
}

.popover.bs-popover-right {
  padding: 5px;
}

.alertPLZ {
  border: 1px solid red !important;
}

.was-validated select.form-control:valid,
select.form-control.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat !important;
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat !important;
}

.emailLink {
  color: darkgreen;
}

.is-sticky {
  position: sticky;
  top: 80px;
  z-index: 500;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

@media (max-width: 992px) {
  .navbar .navbar-nav .nav-item {
    padding: 0px 5px;
  }

  .navbar .navbar-nav .dropdown-menu {
    border-radius: 0;
    border: none;
    background: transparent !important;
  }

  .navbar.navbar-light .navbar-nav .nav-item {
    padding: 0px 5px !important;
  }

  .navbar.navbar-light .navbar-nav .show>.nav-link {
    color: #000000;
  }

  .navbar.navbar-light .navbar-nav .nav-link {
    color: #ffffff;
  }

  .navbar.navbar-light .nav-link {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .alh_counter,
  .alh_mobileHide {
    display: none;
  }

  .alh_mobileFull {
    width: 100%;
  }

  .alh_mobileShow {
    display: block !important;
  }

  .alhFooter .alh_flinks,
  .alhFooter .alh_sm_center {
    text-align: center !important;
  }

  .alh_navSearch {
    margin-bottom: 10px;
  }

  .alh_mgsearch {
    display: none;
  }
}

@media (max-width: 630px) {

  /* Elements to hide in mobile */
  .alh_widget_cont {
    display: none;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 1060px;
  }
}

