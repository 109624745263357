$base-color: #3db5e6;
$oposite-color: #ffc800;
$warning-color: #fd4747;

$checkGreen: #28a745;

$tiles-bgcolor: #e9ecef;
$bg_bars: #4e5457;

@import "Slider";
@import "ToastCustom";
@import "print";

@font-face {
  font-family: 'Exo 2';
  src: url('../assets/font/exo-2-v4-latin-regular.woff2') format('woff2');
}
html {
  height: 100%;
}
body {
  font-family: 'Open Sans',sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 14px !important;
  color: #333;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  &.login {
    background-image: url(../assets/images/login-register-bg.jpg);
  }

  a, .alh_onclicklink {
    color: $base-color;
    cursor: pointer;

    &:hover {
      color: $base-color;
    }
  }

  //Darken links in tables
  table {
    a {
      filter: brightness(0.85);

      &:hover {
        filter: brightness(1);
      }
    }
  }
}

.alh_clickicon .svg-inline--fa {
  cursor: pointer;
}

b {
  font-weight: bold !important;
}

.alh_mobileShow {
  display: none;
}

.alh_bold {
  font-weight: bold;
}

.alh_print_right {
  width: 100%;
  text-align: right;
}

.alh_small {
  font-size: 0.7rem;
}

.inputField {
  select {
    font-size: 14px;
  }

  input{
    &[type="date"] {
      text-transform: uppercase;
    }
  }
}

/** search field */
.isform-control {
  flex: 1 1 auto;
}
.alh_searchItem {
  display: block;
  textAlign: left;
  cursor: pointer;
}
.isform-control-disabled.isSearchAutomcomplete .wrapper {
  background-color: lightgrey;

  input {
    pointer-events: none;
  }
}
.isSearchAutomcomplete .wrapper {
  padding-left: 8px;
  padding-right: 8px;

  &:focus-within {
    z-index: 502;
  }
}
.isSearchAutomcomplete .wrapper input {
  padding-left: 0;
}

.custom-select {
  height: calc(1.5em + .75rem + -2px) !important;
  padding: 0.3rem 1.7rem .3rem .5rem !important;
}

.alh_checksubtextalign {
  position: relative;
  top: -2px;
}

.pointer {
  cursor: pointer;
}

Button {
  border-radius: 0 !important;

  .alh_loadingSpinner {
    padding-left: 10px;
  }

  &.alhButtonSpaceBottom {
    margin-bottom: 5px;
  }

  &.alhButton {

    &.alhButtonFullWidth {
      width: 100%;
    }

    &:disabled {
      cursor: no-drop;
    }

    &.alhButton_mgsave {
      font-size: 1.1em;
      margin-bottom: 10px;
    }
  }
}

.underConstruction {
  align-content: center;
  text-align: center;
}
.notAllowed {
  align-content: center;
  text-align: center;
}

.form-group {
  &.small-form-group {
    margin-bottom: 0px;
  }
}
.formInline {
  width: 70px !important;
  display: inline !important;

  &.formInlineSpacing {
    margin: 0 5px;
  }
}

.btn-primary {
  color: #fff;
  background-color: $base-color !important;
  border-color: lighten($base-color, 20%) !important;

  &:hover {
    background-color: $oposite-color !important;
    border-color: lighten($oposite-color, 50%) !important;
  }

  svg.svg-inline--fa.fa-info-circle.fa-w-16.infoLeftSpace.lightIcon {
    color: #ffffff;
  }
}

h1 {
  font-size: 1.5em !important;
  text-transform: uppercase;
}
h2 {
  font-size: 1.4em !important;
}
h3 {
  font-size: 1.3em !important;
}

.alh_faicon {
  color: inherit;
  line-height: 1rem;
}


/** NAVBAR */
.alhScrolled {

  .navbar.navbar-light {
    img {
      height: 50px;
    }

    .navbar-nav {
      .nav-item {
        > a {
          padding: 11px 5px;
        }
      }
    }
  }

}


.navbar.navbar-light {
  background-color: $base-color;
  padding: 0 15px !important;
  z-index: 503;

  .alh_logobar {
    max-height: 90px;
  }

  button:hover {
    background: $oposite-color;
  }

  .alh-nav-head {
    text-transform: uppercase;

    .dropdown-menu {
      text-transform: none;
    }
  }

  .navbar-nav {
    .nav-item {
      padding: 5px 5px;

      &:hover {
        background: #ffcc00;
      }
    }

    .show > .nav-link,
    .nav-link {
      color: #ffffff;
      padding-top: 30px;
      padding-bottom: 30px;

      &:hover {
        color: #ffffff;
      }
    }
    .dropdown-menu {
      border-radius: 0;
      min-width: 12rem;

      background: #4f5458;
      .nav-link {
        margin: 0;
        padding: 5px 10px;
        font-size: 0.8rem;

        &:hover {
          background: #212222;
        }
      }
    }
  }
}

.nav-tabs {
  &.nav {
    margin-bottom: 20px;
  }

  .nav-link {
    border: none;

    &.active {
      border: none;
      border-bottom: 5px solid $oposite-color !important;
    }
  }

  a {
    color: #000000;

    &:hover {
      border-color: transparent !important;
      color: #000000;
      border-bottom: 4px solid #a5a5a5 !important;
    }
  }
}



/** CORE */
.alhCore {
  margin: 25px auto;
  margin-top: 120px;
  min-height: calc( 100vh - 178px);

  .loginForm {
    position: absolute;
    text-align: center;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -40%);
    z-index: 0;

    .alh_logo {
      text-align: center;

      img {
        width: 115px;
        padding-bottom: 25px;
        vertical-align: center;
      }
    }

    .loginHeader {
      margin-bottom: 25px;
      font-weight: 700;
    }

    .input-group-text {
      width: 40px;
    }
  }

  .alh_pageheader {
    margin-bottom: 15px;
  }
  .alh_pageheader_topspace {
    margin-top: 20px;
  }
  .alh_subheader {
    margin: 0 0 15px 0;
    font-size: 0.8rem;
  }
}

/** JUMBO */
.alh_jumbo {
  position: relative;
  z-index: 2;
  background-size: cover;
  padding: 20px 0 10px 0 !important;
  background-color: $tiles-bgcolor !important;
  box-shadow: 3px 8px 17px #8888887d;

  div {
    line-height: 1.3em;
  }
}

/** FOOTER */
.alhFooter {
  background-color: $bg_bars;
  color: white;
  text-align: left;
  font-size: 14px;
  line-height: 23px;
  padding: 5px 15px;
  z-index: 501;

  a {
    color: #ffffff;

    &:hover {
      color: $oposite-color;
    }
  }

  .alh_mgsearch {

    > div {
      width: fit-content;
      margin: auto;
    }

    input {
      width: 100px;
      margin-left: 10px;
      padding: 2px 3px;
      height: 24px;
    }
  }

  .alh_flinks {
    text-align: right;
  }

  .alh_icon {
    margin-left: 5px;
    cursor: pointer;
  }
}

.warning-text {
  color: #f58080;
}

.inCheckbox {
  padding-left: 5px;
}

.infoSmall {
  font-size: 11px;

  &.warning {
    color: #f58080;
  }

  &.success {
    color: #36b37e;
  }
}

.highlightValue {
  color: #36b37e;
  font-weight: bold;
}
.alh_mitglied_noterwb {
  color: $warning-color;
  font-weight: bold;
  margin-top: 10px;
  font-size: 0.8rem;
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

.alh_widget_bst {
  font-weight: bold;
  margin-bottom: 10px;
}

/** Normal Table */
.alh_tablemaxheight {

  overflow-y: auto;
  max-height: 235px;

  thead th {
    position: sticky;
    top: -1px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    margin-left: 5px;
    background-color: $base-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $base-color;
  }
}

.alhTable {
  background-color: #ffffff;

  .alhTable_SmallInfo {
    font-size: 0.8rem;
    color: $warning-color;
  }

  th {
    font-weight: normal;
    vertical-align: middle !important;

    &.alh_mgEditLables {
      width: 170px;
    }

    &.alhtable_nr {
      width: 80px;
    }
  }
  thead {
    th {
      font-weight: bold;
      padding-right: 0.3rem;
    }
  }

  tr {
    &.highlight {
      background-color: lighten($base-color, 30%) !important;

      &:hover {
        background-color: inherit !important;
      }

      th {
        font-weight: bold;
      }
    }
    &.warning {
      background-color: lighten($warning-color, 30%) !important;
    }
    &.deleted {
      text-decoration: line-through;
      background-color: lighten($warning-color, 30%) !important;
    }

    .alh_tablecheckbox {
      width: 30px;
      text-align: center;
    }
    td.alh_centerWithInput {
      padding-top: 9px;
    }

  }

  //Datum
  .alh_notizaufgaben .alh_notizaufgabenDatum {
    width: 85px;
  }
  //Titel
  .alh_notizaufgaben .alh_notizaufgabenTitle {
    width: 40%
  }
  //Actions
  .alh_notizaufgaben .alh_notizaufgabenAction {
    width: 75px
  }

  .alh_table_prio {
    width: 52px;
  }

  .tableActions {
    width: 52px;
    text-align: center;

    a {
      color: #000000;
    }

  }

  &.alhTable_clearMarginBottom{
    margin-bottom: inherit;
  }

  &.alhTableThFixer {
    tr {
      th:nth-child(1) {
        width: 170px;
      }
    }
  }
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255,255,255, 1);
}

.alh_clearMarginBottom{
  margin-bottom: 0 !important;
}
.halfSize {
  width: 50%;
}
.halfSize_float {
  width: 50%;
  float: left;
}
.onethirdSize_float {
  width: 33%;
  float: left;
}
.twothirdSize_float {
  width: 67%;
  float: left;
}
.fullSize {
  width: 100%;
}

/** Boxes */
.alh_frontBoxes {
  background-color: $tiles-bgcolor;
  padding: 10px 10px;
  min-height: 284px;
  box-shadow: 3px 8px 17px #8888887d;
  height: 100%;

  &.clearMinheight {
    min-height: inherit;
  }

  &.maxHeight {
    max-height: 450px;
    overflow-y: auto;
  }

  .info {
    margin: 10px;
    text-align: center;
    font-weight: bold;
  }

  h2 {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 14px;
  }

  .slider {
    .slider-content {
      height: 100%;
    }
  }

  .alhProgressbar {
    background-color: #ffffff;
  }
}


/** Accordion */
.alhAccordion {

  margin: 15px 0 0 0;

  .alhAcc_Item {

    border-radius: 15px;

    .alhAcc_Header {
      font-weight: bold;
      margin: 0 0 10px 0;
      padding: 10px;
      background-color: lighten($base-color, 30%);
      cursor: pointer;

      &:hover {
        background-color: $oposite-color;
      }

      .alhAll_HeaderTitle {
        float: left;
        width: 75%;
      }
      .alhAll_HeaderCheck {
        float: left;
        width: 10%;

        .inCheckbox label {
          margin-bottom: 0;
        }
      }
      .alhAll_HeaderInfo {
        float: right;

        span {
          padding-left: 5px;
        }
      }
    }
    .alhAcc_Body {
      padding: 5px 0;

      .alhAcc_Notiz,
      .alhAcc_next {
        padding: 10px 10px;
        text-align: end;
      }

      .alhAcc_Frage {
        padding: 5px 10px;

        .alhAcc_FrageName {
          float: left;
          width: calc(100% - 130px);
        }
        .alhAcc_FrageCheck {
          float: left;
          width: 100px;

          .alhAcc_noshow {
            visibility: hidden;
          }

          label {
            padding-right: 5px;
            margin-bottom: 0;
          }
        }

        .alhAcc_FrageOptCheck {
          padding-right: 5px;
        }

        &:hover {
          background-color: lighten($oposite-color, 30%);
        }
      }
    }

    &.alhAcc_Close {

      .alhAcc_Body {
        display: none;
      }

    }
    &.alhAcc_Open {

      .alhAcc_Body {
      }

    }

    .alhAcc_checkboxinline {
      display: contents;
    }

    background-color: #ffffff;
    margin: 0 0 10px 0;

  }

}

/** BP */
.alh_iconright {
  float: right;
}
.alhBP_tripple {
  display: flex;
  margin: 10px 0;

  > div {
    flex: 1;
    margin-top: 5px;

    &.inputField {
      margin-top: 0;
    }
  }

  .form-group {
    margin: 0;
  }
}
.alhBP_LegCheck {
  color: $checkGreen;
  font-size: 1rem;
}
.alhBP_LegGrey {
  color: $bg_bars;
  font-size: 1rem;
}
.alh_bpaendern {
  margin-bottom: 5px;
  padding: 8px 5px;
  background-color: $tiles-bgcolor;
  display: flex;

  > div {
    &:nth-child(1) {
      width: 100px;
    }
  }
}
.alhBP_Leg {
  margin: 5px 0;

  .alhBP_LegItem {

    padding: 2px 0;

    &.alhBP_LegGreen {
      color: $checkGreen;
    }

    .alhBP_LegCheck,
    .alhBP_LegXMark {
      padding-right: 5px;
      font-size: 1.1rem;
    }

    .alhBP_LegCheck {
      color: $checkGreen;
    }
  }

}

.infoLeftSpace {
  margin-left: 5px;
}

.infoRightSpace {
  margin-right: 5px;
}

.alh_infoinputext {
  font-size: 1rem;
  margin: auto 5px;
}

/** Buttons */
.faButton {
  cursor: pointer;
  align-items: baseline;
  margin-right: 5px;
}
.faButtonSpaceright {
  margin: 0 5px;
}

.alh_rowTopList {
  margin: 0 -30px !important;
  margin-bottom: 10px !important;

  &.alh_clearMarginBottom {
    margin-bottom: 0 !important;
  }
}
.alh_containerNoPadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.alh_container_home {
  margin-bottom: 2rem;
}

.alh_spacerTop {
  margin-top: 45px;
}


.alh_rowSpacer {
  margin-bottom: 10px;

  .alh_colData {
    background-color: $tiles-bgcolor;
    margin: 0 0 0 0;
    padding: 5px 10px;

    h3 {
      font-size: 19px;
      color: #000000;
      padding: 8px 0 3px 0;
    }

    table {
      margin: 5px 0 5px 0 !important;

      tr td:first-child {
        font-weight: bold;
        width: 150px;
      }

      .large {
        width: 300px !important;
      }

      .input-group, .form-group {
        margin-bottom: 0 !important;
      }
    }
  }
}

/** Dropzones */
.dzu-dropzone {
  background-color: #ffffff;
  overflow: auto !important;
  border: 1px solid lightgrey !important;
  min-height: 110px !important;

  .dzu-inputLabel {
    color: #858585;
    font-weight: normal;
    font-family: inherit;
    font-size: 0.7rem;
    padding: 20px;
    text-align: center;
  }

  .dzu-previewContainer {
    padding: 0px 20px;
  }

  label.dzu-inputLabelWithFiles {
    color: #000000;
  }

  .dzu-inputLabelWithFiles {
    display: none;
  }
}

.alh_counter {
  position: absolute;
  top: 32px;
  font-size: 9px;
  font-weight: bold;
  padding: 2px 6px;
  color: $warning-color;
  font-family: tahoma, arial, 'sans-serif';
  background-color: #ffffff;
  border-radius: 13px;
  margin-left: 10px;
}

.alh_stat {

  .alh_stat_keys {
    margin-bottom: 10px;

    span {
      font-weight: bold;

      &.success {
        color: $base-color;
      }

    }
  }

}

.alhScrolled {

  .alh_widget_cont {
    top: 60px;
  }

}

.alh_widget_cont {

  position: fixed;
  right: 12px;
  top: 100px;
  margin: 10px;
  z-index: 500;

  .alh_widget_item {

    cursor: pointer;

    img {
      width: 35px;
      background-color: $tiles-bgcolor;
      box-shadow: 3px 5px 6px #8888887d;
      padding: 6px;
      border-radius: 40%;
      margin-bottom: 10px;
      height: 35px;
    }
  }

}

.alh_img100 {
  width: 100%;
}

.lightIcon {
  color: #807f7f;
}

.popover {
  max-width: 600px !important;

  .alh_popover {
    width: 600px;
    padding: 5px;
    overflow: auto;

    h1 {
      font-size: 0.9rem;
      margin-bottom: 5px;
    }
    h2 {
      font-size: 0.8rem;
      margin-bottom: 2px;
    }
  }
}


.alhLoadingPos {
  margin: 25px 0;
}

.alhLoadingRel {
  div {
    position: relative !important;
  }
}

/** sort */
th.alh_sort_active {
  color: $oposite-color;
  cursor: pointer;
  margin-right: 10px;

  &.alh_sort_active_up::after {
    content: "";
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $oposite-color;
    margin: 7px 0px 0px 5px;
    position: relative;
    top: -12px;
  }
  &.alh_sort_active_down::after {
    content: "";
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $oposite-color;
    margin: 7px 0px 0px 5px;
    position: relative;
    top: 12px;
  }
}
th.alh_sort {
  cursor: pointer;
  padding-right: 20px;
}

.alh_createBlankoInfo {

}
.alh_mgp_infoprint {
  line-height: 1rem;
  font-size: 0.7rem;
  padding-bottom: 10px;
}

.alh_iconcont {
  margin-top: 3px;
  font-size: 1.1em;
  text-align: right;
}

.alh_hilfelink {
  position: absolute;
  top: 15px;
  right: 4px;
}

.pulsate {
  /* Chrome, Safari, Opera */
  -webkit-animation: pulsate 1s infinite;
  /* Internet Explorer */
  -ms-animation: pulsate 1s infinite;
  /* Standard Syntax */
  animation: pulsate 1.25s infinite;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes pulsate{
  100%{color: rgba(0, 0, 0, 0.84);}
  0%{color:rgba(255,0,255, 0);}
}
/* Internet Explorer */
@-ms-keyframes pulsate{
  110%{color: black;}
  0%{color:rgba(255,255,0, 0);}
}
/* Standard Syntax */
@keyframes pulsate{
  110%{color: black;}
  0%{color:rgba(0,255,255, 0);}
}

.popover.bs-popover-right {
  padding: 5px;
}

.alertPLZ {
  border: 1px solid red !important;
}


.was-validated select.form-control:valid, select.form-control.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat !important;
}
.was-validated select.form-control:invalid, select.form-control.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat !important;
}


.is-sticky {
  position: sticky;
  top: 80px;
  z-index: 500;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}


@import "responsive";
