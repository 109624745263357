
.Toastify__toast-container--top-right {
  top: 5em !important;
  right: 1.3em !important;
}

.Toastify__toast {
  border-radius: 4px !important;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px !important;

  .Toastify__progress-bar--dark {
    background: $oposite-color;
  }

  &.Toastify__toast--error {
    color: rgb(191, 38, 0);
    background-color: rgb(255, 235, 230);
    border-left: 8px solid;

    .Toastify__toast-body {
      margin: 3px 5px 0 0 !important;
      flex: 1 1 auto;
    }

    .Toastify__close-button {
      color: rgb(191, 38, 0);
      background: transparent;
      margin-top: 2px;
    }
  }


  &.Toastify__toast--success {
    color: rgb(54, 179, 126);
    background-color: rgb(227, 252, 239);
    border-left: 8px solid;

    .Toastify__toast-body {
      margin: 3px 5px 0 0 !important;
      flex: 1 1 auto;
    }

    .Toastify__close-button {
      color: rgb(54, 179, 126);
      background: transparent;
      margin-top: 2px;
    }
  }


}