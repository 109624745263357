@media (max-width: 992px) {

  .navbar .navbar-nav .nav-item {
    padding: 0px 5px;
  }
  .navbar .navbar-nav .dropdown-menu {
    border-radius: 0;
    border: none;
    background: transparent !important;
  }

  .navbar.navbar-light {
    .navbar-nav {
      .nav-item {
        padding: 0px 5px !important;
      }

      .show>.nav-link {
        color: #000000;
      }

      .nav-link {
        color: #ffffff;
      }
    }

    .nav-link {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }

  .alh_counter,
  .alh_mobileHide {
    display: none;
  }

  .alh_mobileFull {
    width: 100%;
  }

  .alh_mobileShow {
    display: block !important;
  }

  .alhFooter {
    .alh_flinks,
    .alh_sm_center {
      text-align: center !important;
    }
  }

  .alh_navSearch {
    margin-bottom: 10px;
  }

  .alh_mgsearch {
    display: none;
  }

}



@media (max-width: 630px) {

  /* Elements to hide in mobile */
  .alh_widget_cont {
    display: none;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 1060px;
  }
}