@media print {

  .alh_noprint,
  .alh_widget_cont {
    display: none !important;
  }

  .alh_mobileHide {
    display: table-cell !important
  }

  .container {
    overflow: hidden;

    .alh_frontBoxes {
      padding: 0;
    }
  }

  .col-print-6 {
    width:50% !important;
    float:left;
  }

  .submitButton {
    display: none;
  }

  .nav-tabs {
    &.nav {
      display: none;
    }
  }
}
